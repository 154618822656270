import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Page from "~common/components/Page";
import PaymentMethods from "~shop/components/PaymentMethods";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Page seo={{
  title: "Payment methods"
}}>
    <Page.Header downArrows>
      <Page.Header.Title>Payment methods</Page.Header.Title>
    </Page.Header>
    <Page.Body sx={{
    py: [5, 6]
  }}>{children}</Page.Body>
  </Page>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can choose to pay either with Credit Card (through Stripe) or PayPal.`}</p>
    <PaymentMethods variant="white-strip" sx={{
      maxWidth: 400,
      mx: [4, 5, "auto"],
      my: [4, 5]
    }} mdxType="PaymentMethods" />
    <p>{`Security is our main concern when handling information. We've gone the extra mile to ensure full security and privacy for customers and users, from the moment the cart pops up until the order is completed. To handle carts and payments we use technologies hosted with `}<a parentName="p" {...{
        "href": "https://www.pcisecuritystandards.org/minisite/env2/"
      }}>{`PCI compliance specifications`}</a>{`. We never store any credit card information. Each request to the backend e-commerce platform is encrypted through TLS.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      